import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PixelStreamingComponent = () => {
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const location = useLocation();
  const [projectName, setProjectName] = useState('');
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const projectId = params.get('projectId');
    const configId = params.get('configId');

    // Set the project name
    setProjectName(`Project ${projectId}`); 

    const script = document.createElement('script');
    const projectKey = '810844c1-41ca-4c84-a245-8b4ed7bb20d2'; // Hardcoded project key

    // Add cache-busting to the script URL to prevent caching issues
    script.src = `https://embed.arcanemirage.com/${projectKey}/e?v=${new Date().getTime()}`;
    script.defer = true;

    window.addEventListener('ArcanePlayerLoaded', () => {
      console.log('ArcanePlayer loading:');
      const loadedPlayer = window['ArcanePlayer'];
      console.log('ArcanePlayer loaded:', loadedPlayer);
      setPlayer(loadedPlayer); 

      // Setting up event listeners
      loadedPlayer.onReceiveEvent('CustomUIEventResponse', (response) => {
        console.log({ ArcaneResponse: response });
      });

      loadedPlayer.onPlayerEvent('loading', () => {
        console.log('loading');
      });

      loadedPlayer.onPlayerEvent('ready', () => {
        console.log('ready');
        
        // Start playing the stream
        loadedPlayer.play();
        
        // Add a delay before emitting the project data
        setTimeout(() => {
          loadedPlayer.emitUIEvent({
            event: 'ProjectConfigData',
            data: {
              token,
              projectId,
              configId,
            },
          });
          
          console.log('sent start ui event');
        }, 3000); // Delay in milliseconds (500ms = 0.5 seconds)
      });

      // Other event listeners as needed...
    });

    window.onerror = function (message, source, lineno, colno, error) {
      console.error('Global error handler:', {
        message,
        source,
        lineno,
        colno,
        error,
      });
    };
    
    script.onload = () => {
      console.log('Arcane Mirage script loaded successfully');
      setLoading(false); 
    
      if (window['initArcanePlayer']) {
        window['initArcanePlayer']();
        console.log('initArcanePlayer success');
    
        // Use MutationObserver to detect when ArcanePlayer is added to the window
        const observer = new MutationObserver((mutationsList, observer) => {
          if (window['ArcanePlayer']) {
            console.log('ArcanePlayer detected via MutationObserver:', window['ArcanePlayer']);
            setPlayer(window['ArcanePlayer']);
            observer.disconnect(); // Stop observing once player is detected

            // Start playing the stream if not already started
            window['ArcanePlayer'].play();
          }
        });
    
        // Start observing the document or specific DOM elements
        observer.observe(document, {
          childList: true,
          subtree: true,
        });
    
        // Fallback to stop observing after a reasonable time
        setTimeout(() => {
          observer.disconnect();
          if (!window['ArcanePlayer']) {
            console.error('ArcanePlayer is still undefined after waiting with MutationObserver.');
          }
        }, 10000); // Stop after 10 seconds
      } else {
        console.error('initArcanePlayer is not defined');
      }
    };
    
    script.onerror = () => {
      console.error('Failed to load Arcane Mirage script');
      setLoading(false); 
    };

    document.body.appendChild(script); 
  }, [location.search]);

  return (
    <div id="am-container" style={{ width: '100%', height: '100vh' }}>
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          color: '#fff',
          fontSize: '18px'
        }}>
          Loading...
        </div>
      ) : (
        <div
          id="arcane-player"
          data-project-id="3402"
          data-project-key="810844c1-41ca-4c84-a245-8b4ed7bb20d2"
          data-token="PVGhz_vJ8BPi"
          data-enable-events-passthrough={true}
        ></div>
      )}
    </div>
  );
};

export default PixelStreamingComponent;
