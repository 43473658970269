import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../hooks/useApiClient';  
import { 
  StepContainer, 
  Column, 
  ColumnTitle, 
  FieldContainer, 
  Label, 
  Description, 
  Input, 
  Switch, 
  ImageDropArea, 
  ImagePreview, 
  ErrorText,
  RowContainer,
  SeparatorColumn,
  Button 
} from '../../styles/creation_steps_styles/ProjectConfigurationStyles';

const ProjectConfiguration = ({ projectData, setProjectData, nextStep, StepEnum }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [errors, setErrors] = useState({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [propertyPlanName, setPropertyPlanName] = useState('');
  const apiClient = useApiClient();  
  const [isLoading, setIsLoading] = useState(false);


  const handleInputChange = (field, value) => {
    if (field === 'propertyValue') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setProjectData({ ...projectData, [field]: numericValue });
    } else {
      setProjectData({ ...projectData, [field]: value });
    }

    if (field === 'propertyPlan' && value) {
      setPropertyPlanName(value.name);
    }

    validateFields(); 
  };

  const handleBlur = (field, value) => {
    if (field === 'propertyValue' && value) {
      const formattedValue = Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setProjectData({ ...projectData, [field]: formattedValue });
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      handleInputChange('imageCover', file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleImageChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleImageChange(file);
  };

  const handleSliderChange = (field, value) => {
    if (field === 'configurations') {
      value = Math.max(10, Math.min(50, value));
    } else if (field === 'streamingMinutes') {
      value = Math.max(60, Math.min(600, value));
    }
    handleInputChange(field, value);
  };

  const validateFields = () => {
    const newErrors = {};

    if (!projectData.propertyName) newErrors.propertyName = 'This field is required';
    if (!projectData.imageCover) newErrors.imageCover = 'This field is required';
    if (projectData.isFurnished === undefined) newErrors.isFurnished = 'This field is required';
    if (!projectData.propertyPlan) newErrors.propertyPlan = 'This field is required';
    if (!projectData.configurations) newErrors.configurations = 'This field is required';
    if (!projectData.streamingMinutes) newErrors.streamingMinutes = 'This field is required';

    setErrors(newErrors);
    setIsNextDisabled(Object.keys(newErrors).length > 0);
  };

  useEffect(() => {
    validateFields();

    if (projectData.imageCover && !imagePreview) {
      setImagePreview(URL.createObjectURL(projectData.imageCover));
    }

    if (projectData.propertyPlan && !propertyPlanName) {
      setPropertyPlanName(projectData.propertyPlan.name);
    }
  }, [projectData, imagePreview, propertyPlanName]);

  const handleNext = async () => {

    validateFields();

    if (Object.keys(errors).length > 0) {
      // If there are validation errors, don't proceed
      return;
    }

    setIsLoading(true);  // Disable the button and grey it out
    const formData = new FormData();
    formData.append('project_name', projectData.propertyName);
    formData.append('project_address', projectData.propertyAddress);
    formData.append('listing_link', projectData.listingLink);
    formData.append('price', projectData.propertyValue);
    formData.append('house_plan_file', projectData.propertyPlan);
    formData.append('cover_image', projectData.imageCover);
    formData.append('active', false); 
    formData.append('is_furnished', projectData.isFurnished);
    console.log("started sending: ", formData);

    
    const paymentSourceData = new FormData();
    paymentSourceData.append("source_page", "PROJECT_CONFIG")
    console.log("paymentSourceData: ", paymentSourceData);

    try {
      
      const response = await apiClient.post('/projects/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('response from project creation: ', response);
      console.log("response config id: ", response.data.configuration.id);

      if (response.status === 201) {
        console.log('Project created successfully: ', response);
        if (!projectData.isFurnished) {
          nextStep(StepEnum.CONFIRMATION_PAGE);  // Call nextStep with the appropriate enum value
        } else {
          paymentSourceData.append("item_lookup_key", "request_furniture")
          paymentSourceData.append("config_id", response.data.configuration.id)
          const paymentResponse = await apiClient.post('/stripe/create-checkout-session', paymentSourceData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (paymentResponse.data.url) {
            // Redirect the user to the Stripe checkout page
            window.location.href = paymentResponse.data.url;
          } else {
              console.error('Failed to get checkout session URL:', paymentResponse.data);
          }
        }
      } else if (response.status === 202) {
        if (!projectData.isFurnished) {
          nextStep(StepEnum.CHECK_PAYMENT);
          console.log('No available project slots. Moving to Upgrade Plan');
        } else {
          paymentSourceData.append("item_lookup_key", "request_furniture")
          paymentSourceData.append("config_id", response.data.configuration.id)
          const paymentResponse = await apiClient.post('/stripe/create-checkout-session', paymentSourceData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (paymentResponse.data.url) {
            // Redirect the user to the Stripe checkout page
            window.location.href = paymentResponse.data.url;
          } else {
              console.error('Failed to get checkout session URL:', paymentResponse.data);
          }
        }
      } else {
        console.error('Failed to create project:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
    setIsLoading(false); // Re-enable fields in case of error
  }
  };

  return (
    <StepContainer>
      <Column>
        <ColumnTitle>Property Data</ColumnTitle>
        <FieldContainer>
          <Label>Upload an Image Cover for this Property</Label>
          <Description>Drag and drop an image type file</Description>
          <ImageDropArea 
            onClick={() => !isLoading && document.getElementById('imageUpload').click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            dragging={dragging}
          >
            {imagePreview ? (
              <ImagePreview src={imagePreview} alt="Image Preview" />
            ) : (
              <span>+</span>
            )}
            <input 
              id="imageUpload" 
              type="file" 
              accept="image/*" 
              onChange={handleFileInputChange} 
              style={{ display: 'none' }}
              disabled={isLoading} // Disable during loading

            />
          </ImageDropArea>
          {errors.imageCover && <ErrorText>{errors.imageCover}</ErrorText>}
        </FieldContainer>

        <RowContainer>
        <FieldContainer>
          <Label>Property Name</Label>
          <Input 
            type="text" 
            value={projectData.propertyName || ''} 
            onChange={(e) => handleInputChange('propertyName', e.target.value)} 
            disabled={isLoading} // Disable during loading

          />
          {errors.propertyName && <ErrorText>{errors.propertyName}</ErrorText>}
        </FieldContainer>

        <FieldContainer>
          <Label>Property Address</Label>
          <Input 
            type="text" 
            value={projectData.propertyAddress || ''} 
            onChange={(e) => handleInputChange('propertyAddress', e.target.value)} 
            disabled={isLoading} // Disable during loading
          />
        </FieldContainer>
      </RowContainer>

      <RowContainer>
        <FieldContainer>
          <Label>Property Value ($ USD)</Label>
          <Input 
            type="text" 
            value={projectData.propertyValue || ''} 
            onChange={(e) => handleInputChange('propertyValue', e.target.value)} 
            onBlur={(e) => handleBlur('propertyValue', e.target.value)}
            disabled={isLoading} // Disable during loading
          />
        </FieldContainer>

        <FieldContainer>
          <Label>Listing Link</Label>
          <Input 
            type="text" 
            value={projectData.listingLink || ''} 
            onChange={(e) => handleInputChange('listingLink', e.target.value)} 
            disabled={isLoading} // Disable during loading
          />
        </FieldContainer>
      </RowContainer>
      </Column>
      <SeparatorColumn></SeparatorColumn>
      <Column>
        <ColumnTitle>Virtual Configuration</ColumnTitle>
        <FieldContainer>
          <Label>Shall the property be furnished by us?</Label>
          <Description>Whether you want the virtual property to be furnished by us or you want it empty with only the walls (leads to additional initial set up costs)</Description>
          <Switch 
            checked={projectData.isFurnished || false} 
            onChange={(e) => handleInputChange('isFurnished', e.target.checked)} 
            disabled={isLoading} // Disable during loading
          />
          {errors.isFurnished && <ErrorText>{errors.isFurnished}</ErrorText>}
        </FieldContainer>

        <FieldContainer>
            <Label>Upload the Property Plan</Label>
            <Description>Drag and drop a .dwg or .pdf file</Description>
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <Input 
                    type="file" 
                    accept=".dwg,.pdf" 
                    onChange={(e) => handleInputChange('propertyPlan', e.target.files[0])} 
                    style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
                    disabled={isLoading} // Disable during loading
                />
                <div 
                    style={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: '#ffffff',
                        color: '#333333',
                        cursor: 'pointer'
                    }}
                >
                    {propertyPlanName || 'No file selected'}
                </div>
            </div>
            {errors.propertyPlan && <ErrorText>{errors.propertyPlan}</ErrorText>}
        </FieldContainer>

        <Button onClick={handleNext} disabled={isLoading || isNextDisabled} style={{ backgroundColor: isLoading || isNextDisabled ? '#cccccc' : '#007bff', cursor: isLoading || isNextDisabled ? 'not-allowed' : 'pointer' }}>
          {isLoading ? 'Processing...' : 'Create'}
        </Button>
      </Column>
    </StepContainer>
  );
};

export default ProjectConfiguration;
