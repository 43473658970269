import React, { useEffect } from 'react';
import { 
  ConfirmationContainer, 
  Title, 
  Message, 
  ConfettiContainer,
  SuccessIcon 
} from '../../styles/creation_steps_styles/CreationConfirmationStyles'; // Import the styles

const CreationConfirmation = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/'; // Redirect to homepage after 10 seconds
    }, 30000);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <ConfirmationContainer>
      <SuccessIcon>✔️</SuccessIcon>
      <Title>Congratulations!</Title>
      <Message>Your project has been created successfully!</Message>
      <Message><b>It can take up to 48 hours to be able to get into the experience as we are working on the 3D structure of your property.</b></Message>
      <Message>We'll send you an email as soon as it's ready</Message>
      <Message>You will be redirected to the homepage in 30 seconds.</Message>
    </ConfirmationContainer>
  );
};

export default CreationConfirmation;
