import React, { useState } from 'react';
import { AuthContainer, AuthCard, AuthTitle, AuthInput, AuthButton, AuthLink, ErrorText, PasswordCriteria, PasswordCriteriaItem } from '../styles/AuthFormStyles';
import { Link, useNavigate } from 'react-router-dom';
import { useApiClient } from '../hooks/useApiClient';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [errors, setErrors] = useState({});
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameParts = name.trim().split(' ');
    return nameParts.length >= 2 && nameParts.every(part => part.length >= 2);
  };

  const validateTerms = (tos, pp) => tos && pp;

  const passwordCriteria = validatePassword(password);
  const isPasswordValid = Object.values(passwordCriteria).every(Boolean);
  const isEmailValid = validateEmail(email);
  const isNameValid = validateName(name);
  const isTermsValid = validateTerms(termsOfService, privacyPolicy);

  const handleRegister = async () => {
    if (isPasswordValid && isEmailValid && isNameValid && isTermsValid) {
      try {
        const response = await apiClient.post('/auth/register', {
          name,
          email,
          password,
          terms_of_service: termsOfService,
          privacy_policy: privacyPolicy,
        });

        // Assuming the backend sends a confirmation email
        alert('A confirmation email has been sent. Please check your inbox.');
        navigate('/login');
      } catch (error) {
        console.error('Error registering:', error);
      }
    } else {
      setErrors({
        password: !isPasswordValid,
        email: !isEmailValid,
        name: !isNameValid,
        terms: !isTermsValid,
      });
    }
  };

  return (
    <AuthContainer>
      <AuthCard>
        <AuthTitle>Register</AuthTitle>
        <AuthInput 
          type="text" 
          placeholder="Full Name" 
          value={name} 
          onChange={(e) => {
            setName(e.target.value);
            setErrors((prev) => ({ ...prev, name: !validateName(e.target.value) }));
          }} 
          style={{ borderColor: errors.name ? 'red' : '' }}
        />
        {errors.name && <ErrorText>Please enter a valid full name.</ErrorText>}
        <AuthInput 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors((prev) => ({ ...prev, email: !validateEmail(e.target.value) }));
          }} 
          style={{ borderColor: errors.email ? 'red' : '' }}
        />
        {errors.email && <ErrorText>Please enter a valid email.</ErrorText>}
        <AuthInput 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => {
            setPassword(e.target.value);
            setErrors((prev) => ({ ...prev, password: !validatePassword(e.target.value).length }));
          }} 
          style={{ borderColor: errors.password ? 'red' : '' }}
        />
        <PasswordCriteria>
          <PasswordCriteriaItem met={passwordCriteria.length}>At least 8 characters</PasswordCriteriaItem>
          <PasswordCriteriaItem met={passwordCriteria.specialChar}>Contains a special character</PasswordCriteriaItem>
          <PasswordCriteriaItem met={passwordCriteria.uppercase}>Contains an uppercase letter</PasswordCriteriaItem>
          <PasswordCriteriaItem met={passwordCriteria.lowercase}>Contains a lowercase letter</PasswordCriteriaItem>
          <PasswordCriteriaItem met={passwordCriteria.number}>Contains a number</PasswordCriteriaItem>
        </PasswordCriteria>
        <div>
          <label>
            <input 
              type="checkbox" 
              checked={termsOfService} 
              onChange={(e) => {
                setTermsOfService(e.target.checked);
                setErrors((prev) => ({ ...prev, terms: !validateTerms(e.target.checked, privacyPolicy) }));
              }} 
            />
            Accept <Link to="/terms-of-service">Terms of Service</Link>
          </label>
          {!termsOfService && errors.terms && <ErrorText>You must accept the Terms of Service.</ErrorText>}
        </div>
        <div>
          <label>
            <input 
              type="checkbox" 
              checked={privacyPolicy} 
              onChange={(e) => {
                setPrivacyPolicy(e.target.checked);
                setErrors((prev) => ({ ...prev, terms: !validateTerms(termsOfService, e.target.checked) }));
              }} 
            />
            Accept <Link to="/privacy-policy">Privacy Policy</Link>
          </label>
          {!privacyPolicy && errors.terms && <ErrorText>You must accept the Privacy Policy.</ErrorText>}
        </div>
        <AuthButton onClick={handleRegister}>Register</AuthButton>
        <AuthLink>
          Already have an account? <Link to="/login">Login</Link>
        </AuthLink>
      </AuthCard>
    </AuthContainer>
  );
}

export default Register;
