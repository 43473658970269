import React from 'react';
import { Card, Image, Location, Title } from '../styles/ProjectCardStyles';

const ProjectCard = ({ project, isSelected, onClick, isInactive }) => {
  return (
    <Card selected={isSelected} onClick={onClick}>
      <Image 
        src={project.cover_image || 'https://via.placeholder.com/200'} 
        alt={project.project_name} 
        isInactive={isInactive}  // Pass isInactive to the styled component
      />
      <Title>{project.project_name}</Title>
      <Location>{project.project_address}</Location>
    </Card>
  );
};

export default ProjectCard;
