import styled from 'styled-components';

export const SidebarContainer = styled.div`
  width: 250px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  flex-shrink: 0;
  justify-content: space-between;
`;

export const Logo = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;

  img {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
`;

export const NavItem = styled.div`
  margin: 10px 0;
  color: ${({ $inactive, $active }) => ($inactive ? '#cccccc' : $active ? '#007bff' : '#333333')};
  font-size: 18px;
  cursor: ${({ $inactive }) => ($inactive ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ $inactive }) => ($inactive ? 'none' : 'auto')};
  background-color: ${({ $active }) => ($active ? '#e6f7ff' : 'transparent')};
  padding: 10px;
  border-radius: 5px;

  &:hover {
    color: ${({ $inactive }) => ($inactive ? '#cccccc' : '#007bff')};
    background-color: ${({ $inactive }) => ($inactive ? 'transparent' : '#e6f7ff')};
  }
`;

export const NavItemContent = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: ${({ $inactive }) => ($inactive ? 'grayscale(100%)' : 'none')};
  }
`;

export const BottomNavContainer = styled.div`
  margin-top: auto;
`;
