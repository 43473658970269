import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
`;

export const PrivacyPolicyCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
`;

export const PrivacyPolicyTitle = styled.h1`
  color: #333333;
  font-size: 26px;
  margin-bottom: 20px;
`;

export const PrivacyPolicyContent = styled.div`
  margin-top: 20px;
  color: #333333;
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap;
`;
