import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  flex-shrink: 0;
  position: relative;
  height: 100%; /* Ensure it takes the full height */
`;

export const Dropdown = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  margin-bottom: 20px;
`;

export const Image = styled.div`
  width: 100%;
  aspect-ratio: 1;  /* Ensures the image is square */
  border-radius: 10px;
  position: relative; /* Make sure the gradient stays on top of the image */
  overflow: hidden; /* Ensure the gradient stays within the bounds of the image */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)); /* Black to transparent gradient */
    border-radius: 10px; /* Match the border-radius of the image */
    z-index: 1; /* Ensure the gradient is above the image */
  }
`;



export const Title = styled.h2`
  margin: 10px 0 5px 0;
  color: #333333;
  text-align: center;
`;

export const Address = styled.p`
  margin: 5px 0;
  color: #888888;
  font-size: 14px;
  text-align: center;
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: ${({ $delete, $cancel }) => 
    $delete ? '#dc3545' : $cancel ? '#6c757d' : '#007bff'};
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: ${({ $delete, $cancel }) => 
      $delete ? '#c82333' : $cancel ? '#5a6268' : '#0056b3'};
  }
`;

export const Stats = styled.div`
  margin-top: 20px;
`;

export const StatItem = styled.div`
  margin: 5px 0;
  color: #333333;
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px;
  gap:10px;
`;

export const PriceTag = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
`;

export const DeleteConfirmationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const DeleteInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  margin-bottom: 10px;
`;

export const DeleteButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

export const Switch = styled.input.attrs({ type: 'checkbox' })`
  width: 50px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  appearance: none;
  position: relative;
  cursor: pointer;
  &:checked {
    background-color: #007bff;
  }
  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: 0.2s;
  }
  &:checked:before {
    transform: translateX(26px);
  }
`;

export const NewConfigContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfigInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  margin-bottom: 20px;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SwitchLabel = styled.span`
  font-size: 16px;
  color: #333333;
`;

export const ConfigDeleteContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  color: #dc3545;

  &:hover {
    color: #c82333;
  }
`;

export const ConfigDeleteIcon = styled.span`
  margin-right: 5px;
  font-size: 16px;
`;

export const ProjectSettingsContainer = styled.div`
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const SettingsTitle = styled.h3`
  margin-bottom: 15px;
  color: #333333;
`;

export const DeleteConfigButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #dc3545;
`;

export const DeleteConfigIcon = styled.span`
  margin-right: 8px;
`;

export const DeleteConfigButton = styled.span`
  text-decoration: underline;
`;


export const ThreeDotsButton = styled.div`
  position: absolute;
  width: 24px; /* Adjusted to a larger size */
  height: 24px; /* Adjusted to a larger size */
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;  /* Set image width to 100% of the container */
    height: 100%; /* Set image height to 100% of the container */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow to the image */
    border-radius: 50%; /* Ensure the shadow follows the round shape */
  }
    
  z-index: 2; /* Ensure the gradient is above the image */
`;



export const OptionsMenuContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const OptionsMenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;