import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  StepContainer, 
  Column, 
  ColumnTitle, 
  FieldContainer, 
  Label, 
  Description, 
  Input, 
  Switch, 
  ImageDropArea, 
  ImagePreview, 
  ErrorText,
  RowContainer,
  SeparatorColumn,
  BottomButtonContainer,
  Button
} from '../styles/creation_steps_styles/ProjectConfigurationStyles';

const EditProject = ({ project, onSave, onCancel }) => {
  const [projectData, setProjectData] = useState({
    propertyName: project.project_name || '',
    propertyAddress: project.project_address || '',
    propertyValue: project.price || '',
    listingLink: project.listing_link || '',
    imageCover: project.cover_image || '',
    isFurnished: project.isFurnished || false,
    propertyPlan: project.propertyPlan || '',
    configurations: project.configurations || 0,
    streamingMinutes: project.streamingMinutes || 0,
  });

  const [imagePreview, setImagePreview] = useState(project.cover_image || null);
  const [dragging, setDragging] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setProjectData({ ...projectData, [field]: value });
    validateFields();
  };

  const handleImageChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      handleInputChange('imageCover', file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleImageChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleImageChange(file);
  };

  const validateFields = () => {
    const newErrors = {};

    if (!projectData.propertyName) newErrors.propertyName = 'This field is required';
    if (!projectData.imageCover) newErrors.imageCover = 'This field is required';
    if (!projectData.propertyValue || isNaN(Number(projectData.propertyValue)) || Number(projectData.propertyValue) <= 0) {
        newErrors.propertyValue = 'This field is required and must be a number greater than 0';
    }
    if (projectData.isFurnished === undefined) newErrors.isFurnished = 'This field is required';
    if (!projectData.propertyPlan) newErrors.propertyPlan = 'This field is required';
    if (!projectData.configurations) newErrors.configurations = 'This field is required';
    if (!projectData.streamingMinutes) newErrors.streamingMinutes = 'This field is required';

    setErrors(newErrors);
  };

  useEffect(() => {
    validateFields();
  }, [projectData]);

  const handleSave = async () => {
    // Prepare form data
    const formData = new FormData();
    formData.append('project_name', projectData.propertyName);
    formData.append('project_address', projectData.propertyAddress);
    formData.append('listing_link', projectData.listingLink);
    formData.append('price', projectData.propertyValue);
    formData.append('active', 'None'); // You can change this if there's a specific value to be used
    formData.append('max_streaming_minutes_month', projectData.streamingMinutes);
    formData.append('max_configurations', projectData.configurations);

    if (projectData.imageCover instanceof File) {
        formData.append('cover_image', projectData.imageCover);
    }

    if (projectData.propertyPlan instanceof File) {
        formData.append('house_plan_file', projectData.propertyPlan);
    }

    try {
      const response = await axios.put(`https://versy.tech/api/projects/${project.id}`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log('Project updated successfully');
        onSave(response.data); // Pass the updated data back to the parent component
      } else {
        console.error('Failed to update project:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  return (
    <StepContainer>
      <Column>
        <ColumnTitle>Edit Property Data</ColumnTitle>
        <FieldContainer>
          <Label>Change the Image Cover for this Property</Label>
          <Description>Drag and drop an image type file</Description>
          <ImageDropArea 
            onClick={() => document.getElementById('imageUpload').click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            dragging={dragging}
          >
            {imagePreview ? (
              <ImagePreview src={imagePreview} alt="Image Preview" />
            ) : (
              <span>+</span>
            )}
            <input 
              id="imageUpload" 
              type="file" 
              accept="image/*" 
              onChange={handleFileInputChange} 
              style={{ display: 'none' }}
            />
          </ImageDropArea>
          {errors.imageCover && <ErrorText>{errors.imageCover}</ErrorText>}
        </FieldContainer>

        <RowContainer>
        <FieldContainer>
          <Label>Property Name</Label>
          <Input 
            type="text" 
            value={projectData.propertyName || ''} 
            onChange={(e) => handleInputChange('propertyName', e.target.value)} 
          />
          {errors.propertyName && <ErrorText>{errors.propertyName}</ErrorText>}
        </FieldContainer>

        <FieldContainer>
          <Label>Property Address</Label>
          <Input 
            type="text" 
            value={projectData.propertyAddress || ''} 
            onChange={(e) => handleInputChange('propertyAddress', e.target.value)} 
          />
        </FieldContainer>
      </RowContainer>

      <RowContainer>
        <FieldContainer>
          <Label>Property Value ($ USD)</Label>
          <Input 
            type="text" 
            value={projectData.propertyValue || ''} 
            onChange={(e) => handleInputChange('propertyValue', e.target.value)} 
            onBlur={(e) => handleInputChange('propertyValue', e.target.value)}
          />
        </FieldContainer>

        <FieldContainer>
          <Label>Listing Link</Label>
          <Input 
            type="text" 
            value={projectData.listingLink || ''} 
            onChange={(e) => handleInputChange('listingLink', e.target.value)} 
          />
        </FieldContainer>
      </RowContainer>
      </Column>
      <Column>
      </Column>
      <BottomButtonContainer>
        <Button onClick={handleSave}>Save</Button>
        <Button $delete onClick={onCancel}>Cancel</Button>
      </BottomButtonContainer>
    </StepContainer>
  );
};

export default EditProject;
