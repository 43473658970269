import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f8ff; /* Light blue background */
  text-align: center;
  padding: 60px;
`;

export const ConfettiContainer = styled.div`
  font-size: 4rem;
  margin-bottom: 20px;
  animation: confetti-fall 3s infinite; /* Optional animation for confetti */
`;

export const SuccessIcon = styled.div`
  font-size: 6rem;
  color: #28a745; /* Green color */
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
`;

export const Message = styled.p`
  font-size: 1.5rem;
  color: #555;
  margin-top: 10px;
  max-width: 600px;
`;

