import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Home from './components/Home';
import Login from './components/Login';
import TermsOfService from './components/TermsOfService';  
import PrivacyPolicy from './components/PrivacyPolicy';  
import PixelStreamingComponent  from './components/PixelStreamingComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/projects" />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />  
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />  
        <Route path="/streaming" element={<PixelStreamingComponent />} /> 
        <Route path="/:section/*" element={<Home />} />

      </Routes>
    </Router>
  );
}

export default App;
