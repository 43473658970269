import React, { useState, useEffect } from 'react';
import { useApiClient } from '../hooks/useApiClient';  // Import the custom hook
import { AccountContainer, Header, FormContainer, FormGroup, Label, Input, UploadButton, SaveButton, SubscriptionContainer, PlanName, ManageButton, BenefitsList } from '../styles/AccountStyles';

const Account = () => {
  const [accountInfo, setAccountInfo] = useState({
    name: '',
    email: '',
    phone: '',
    profilePicture: null,
  });

  const [subscription, setSubscription] = useState({
    planName: 'Versy Free Plan',
    benefits: ["a", "b", "c"],
    end_date: '',
    used_minutes: 0,
    manageLink: "https://billing.stripe.com/p/login/test_9AQ8xk5MjfOkaNWbII"
  });

  const apiClient = useApiClient();  // Use the custom hook

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await apiClient.get('/user/');
        if (response.status === 200) {
          const { name, email, phone, profile_picture, subscription_plan } = response.data;
          setAccountInfo({ name, email, phone, profilePicture: profile_picture });
          setSubscription({
            planName: subscription_plan.name,
            benefits: subscription_plan.benefits,
            end_date: subscription_plan.end_date,
            used_minutes: subscription_plan.used_minutes,
          });
        }
      } catch (error) {
        console.error('Error fetching account information:', error);
      }
    };

    fetchAccountInfo();
  }, []);

  const handleInputChange = (field, value) => {
    setAccountInfo({ ...accountInfo, [field]: value });
  };

  const handleFileChange = (e) => {
    setAccountInfo({ ...accountInfo, profilePicture: e.target.files[0] });
  };

  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append('name', accountInfo.name);
    formData.append('email', accountInfo.email);
    formData.append('phone', accountInfo.phone);
    if (accountInfo.profilePicture instanceof File) {
      formData.append('profile_picture', accountInfo.profilePicture);
    }

    try {
      const response = await apiClient.put('/user/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        console.log('Account updated successfully');
        // You might want to refresh or update the UI
      } else {
        console.error('Failed to update account:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating account:', error);
    }
  };

  return (
    <AccountContainer>
      <Header>
        <h1>Account Settings</h1>
      </Header>
      <FormContainer>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={accountInfo.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            value={accountInfo.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            disabled  // Disable the email input field
            style={{ backgroundColor: '#f0f0f0', color: '#888' }}  // Grey out the field
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <Input
            type="tel"
            value={accountInfo.phone}
            onChange={(e) => handleInputChange('phone', e.target.value)}
            disabled
            style={{ backgroundColor: '#f0f0f0', color: '#888' }}  // Grey out the field
          />
        </FormGroup>
        <FormGroup>
          <Label>Profile Picture</Label>
          <Input type="file" accept="image/*" onChange={handleFileChange} />
          {accountInfo.profilePicture && (
            <p>{accountInfo.profilePicture.name ? accountInfo.profilePicture.name : 'Current picture'}</p>
          )}
        </FormGroup>

        {/* New Subscription Plan Section */}
        <SubscriptionContainer>
          <Label>Current Subscription Plan</Label>
          <div>
            <PlanName>{subscription.planName}</PlanName>
            <ManageButton onClick={() => window.location.href = subscription.manageLink}>Manage Plan</ManageButton>
          </div>
          <BenefitsList>
            {subscription.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </BenefitsList>
        </SubscriptionContainer>

        <SaveButton onClick={handleSaveChanges}>Save Changes</SaveButton>
      </FormContainer>
    </AccountContainer>
  );
};

export default Account;
