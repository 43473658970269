import styled from 'styled-components';

export const UpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 100%;
  width: 100%;  /* Ensure it takes the full width of the viewport */
  background-color: #f9f9f9;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 40px;
`;

export const NoUpgradeContainer = styled.div`
  width: 100%;
  margin: 60px auto 0px auto;
`;

export const Message = styled.p`
  font-size: 1.5rem;
  color: #666;
  margin: 0 auto 0px auto;  /* Center the message horizontally using auto margins */
  max-width: 800px;
  text-align: center;  /* Ensure the text inside the message is centered */
`;

export const PricingTableContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;  /* Space between the buttons */
  margin-top: 30px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #909090;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: #909090;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    color: #0056b3;
  }
`;
