import styled from 'styled-components';

export const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  margin: 10px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: ${({ isSelected }) => (isSelected ? '2px solid #007bff' : 'none')};  /* Apply blue border if selected */
  transition: border 0.3s ease;

  &:hover {
    border: 2px solid #007bff;  /* Apply the same blue border on hover */
  }
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;  /* Ensures the image is square */
  object-fit: cover;
  border-radius: 10px 10px 0 0;  /* Ensure the top corners match the card's border-radius */
  filter: ${({ isInactive }) => (isInactive ? 'grayscale(100%)' : 'none')};  /* Apply greyscale if inactive */
`;

export const Title = styled.h3`
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
  padding: 0 10px;  /* Padding applied to the sides of the text */
`;

export const Location = styled.p`
  font-size: 14px;
  color: #888888;
  padding: 0 10px;  /* Padding applied to the sides of the text */
  margin-bottom: 10px;  /* Add margin to create space between the text and the bottom of the card */
`;
