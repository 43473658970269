import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const useApiClient = () => {
    const navigate = useNavigate();

    const apiClient = axios.create({
        baseURL: 'https://versy.tech/api',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
    });

    apiClient.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('access_token');
                navigate('/login');
            }
            return Promise.reject(error);
        }
    );

    return apiClient;
};
