import styled, { css } from 'styled-components';

export const StepContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
  gap: 20px;
`;

export const Column = styled.div`
  flex: 1;
  min-width: 300px;
  padding: 20px;
`;

export const SeparatorColumn = styled.div`
  flex: 0.001;
  background-color: #999999;
  padding: 0px 0px;
`;

export const ColumnTitle = styled.h2`
  font-size: 20px;
  color: #888888;
  margin-bottom: 20px;
`;

export const FieldContainer = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #888888;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
`;

export const Switch = styled.input.attrs({ type: 'checkbox' })`
  width: 50px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  appearance: none;
  position: relative;
  cursor: pointer;
  &:checked {
    background-color: #007bff;
  }
  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: 0.2s;
  }
  &:checked:before {
    transform: translateX(26px);
  }
`;

export const ImageDropArea = styled.div`
  border: 2px dashed #007bff;
  border-radius: 10px;
  width: 50%;
  padding-top: 50%; /* Ensures the area is square */
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  overflow: hidden;

  ${(props) =>
    props.dragging &&
    css`
      background-color: #e0f7ff;
      border-color: #0056b3;
    `}

  span {
    font-size: 36px;
    color: #007bff;
    position: absolute;
  }
`;

export const ImagePreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SliderInput = styled.input.attrs({ type: 'range' })`
  flex: 1;
  margin-right: 10px;
`;

export const SliderValue = styled.input.attrs({ type: 'number' })`
  width: 60px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  text-align: center;
`;

export const ErrorText = styled.p`
  font-size: 14px;
  color: #ff4d4f;
  margin-top: 5px;
`;

export const RecapColumn = styled.div`
  flex: 0.66; /* 33% smaller than the other columns */
  min-width: 200px;
  padding: 20px 40px 0px 20px;
`;

export const RecapCard = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const RecapTitle = styled.h3`
  font-size: 18px;
  color: #333333;
  margin-bottom: 20px;
  margin-top: 0px;
`;

export const RecapItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;

  span {
    color: #888888;
  }

  strong {
    color: #333333;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
  position: absolute;
  bottom: 5vh;
  margin: 0px 20px;
  gap: 10px;
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: ${({ $delete }) => ($delete ? '#dc3545' : '#007bff')};
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: ${({ $delete }) => ($delete ? '#c82333' : '#0056b3')};
  }
`;