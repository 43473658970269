import React, { useEffect, useState } from 'react';
import { UpgradeContainer, Title, Message, ButtonContainer, Button, TextButton, NoUpgradeContainer } from '../styles/UpgradePlanStyles';
import { useApiClient } from '../hooks/useApiClient';
import { useNavigate } from 'react-router-dom';  // Import useNavigate


const UpgradePlan = () => {
  const [uid, setUid] = useState(null);
  const [currPlanId, setCurrPlanId] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const apiClient = useApiClient();
  const navigate = useNavigate();  // Initialize the useNavigate hook

  useEffect(() => {

    const fetchCurrentUserPlan = async () => {
      try {
        const response = await apiClient.get('/user/subscription');
        console.log(response);
        setUid(response.data.uid)
        setCurrPlanId(response.data.sub_id);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchCurrentUserPlan();

    // Load the Stripe pricing table script asynchronously
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on component unmount
    };
  }, [apiClient]);

  useEffect(() => {
    // Set the client-reference-id dynamically after UID is fetched
    const pricingTableElement = document.querySelector('stripe-pricing-table');
    if (uid) {
      if (pricingTableElement) {
        pricingTableElement.setAttribute('client-reference-id', uid);
      }
    }
    if (currPlanId) {
      if (pricingTableElement) { 
        if (currPlanId === 1) {
          pricingTableElement.setAttribute('pricing-table-id', "prctbl_1Pqa6PKz0yq8gQgd8vd1fePp");
        }
        else if (currPlanId === 2) {
          pricingTableElement.setAttribute('pricing-table-id', "prctbl_1Pqi3bKz0yq8gQgdAuzhuKS8");
        }
        else if (currPlanId === 3) {
          pricingTableElement.setAttribute('pricing-table-id', "prctbl_1Pqi4MKz0yq8gQgd5cc4Ep5C");
        }
      }

    }
  }, [uid]);

  const handleGoBack = () => {
    navigate('/');  // Navigate to the home page
  };

  return (
    <UpgradeContainer>
      <Message>Your project has been created successfully! It can take up to <b>48 hours to be able to enter the experience</b> as we are building the structure of your property.<br></br><br></br><b>Oh no! You finished your available slots for active projects. Upgrade your plan for more slots.</b></Message>
      <Title>Upgrade Your Plan</Title>
      <stripe-pricing-table 
        publishable-key="pk_test_51PmAWnKz0yq8gQgdmcmyUL9PF73J430dFa5jujJJACQLbolOo8EXyrg7lA5dZMC7YJQaqiFjidis54q1X6V7AxrG00BLxLYtq5"
        metadata={metadata}>
      </stripe-pricing-table>
      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <NoUpgradeContainer>
        <Message>Or deactivate another project to make space for the new one</Message>
        <ButtonContainer>
          <Button onClick={handleGoBack}>Go back to your Dashboard</Button>  {/* Use handleGoBack */}
        </ButtonContainer>
      </NoUpgradeContainer>
    </UpgradeContainer>
  );
};

export default UpgradePlan;
