import React from 'react';
import { TermsContainer, TermsCard, TermsTitle, TermsContent } from '../styles/TermsOfServiceStyles';

const TermsOfService = () => {
  return (
    <TermsContainer>
      <TermsCard>
        <TermsTitle>Terms of Service</TermsTitle>
        <TermsContent>
          <p><strong>Effective Date:</strong> August 16, 2024</p>

          <p>These Terms of Service ("Terms") govern your use of the Versy Srl ("Versy," "we," "our," "us") website and Text-to-Space platform. By creating an account and using our services, you agree to comply with and be bound by these Terms.</p>

          <h3>1. Acceptance of Terms</h3>
          <p>By accessing or using our platform, you agree to these Terms. If you do not agree to these Terms, you must not use our platform.</p>

          <h3>2. Account Creation and Maintenance</h3>
          <p><strong>Required Information</strong><br />
          To create an account, you must provide your:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Password</li>
          </ul>

          <p><strong>User Responsibilities</strong><br />
          You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to:</p>
          <ul>
            <li>Provide accurate and up-to-date information during the registration process.</li>
            <li>Immediately notify us of any unauthorized use of your account or any other security breach.</li>
          </ul>

          <h3>3. User Conduct</h3>
          <p><strong>Expected Behaviors</strong><br />
          You agree to:</p>
          <ul>
            <li>Comply with all applicable laws and regulations.</li>
            <li>Use our platform for lawful purposes only.</li>
          </ul>

          <p><strong>Prohibited Actions</strong><br />
          You are prohibited from:</p>
          <ul>
            <li>Engaging in any activity that could harm our platform, including hacking, spamming, or distributing malware.</li>
            <li>Sharing, posting, or transmitting any illegal content.</li>
            <li>Using our platform to violate the privacy of others.</li>
            <li>Reproducing, duplicating, or exploiting any part of our platform without our express written permission.</li>
          </ul>

          <h3>4. Intellectual Property</h3>
          <p>All content and materials on our platform, including but not limited to text, graphics, logos, and software, are the intellectual property of Versy and are protected by applicable intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our express written consent.</p>

          <h3>5. Payment and Billing</h3>
          <p><strong>Payment Processing</strong><br />
          All payments are processed through Stripe, and your payment information is retained by Stripe. You agree to Stripe's <a href="https://stripe.com/legal">Terms of Service</a> and <a href="https://stripe.com/privacy">Privacy Policy</a>.</p>

          <p><strong>Refunds</strong><br />
          We do not offer refunds for any payments made on our platform.</p>

          <h3>6. Termination and Suspension</h3>
          <p><strong>Termination by User</strong><br />
          You may terminate your account at any time by sending a termination request to <a href="mailto:info@versytechnologies.com">info@versytechnologies.com</a>.</p>

          <p><strong>Termination or Suspension by Versy</strong><br />
          We reserve the right to suspend or terminate your account at our discretion if you violate these Terms, engage in illegal activities, or engage in conduct that we determine to be harmful to our platform or other users.</p>

          <h3>7. Limitation of Liability</h3>
          <p>Versy will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of our platform. Our platform is provided "as is" and "as available" without any warranties of any kind.</p>

          <h3>8. Governing Law</h3>
          <p>These Terms are governed by and construed in accordance with the laws of Italy. Any disputes arising out of or relating to these Terms will be resolved in the courts of Italy.</p>

          <h3>9. Amendments</h3>
          <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by sending an email to the address associated with your account. Amendments will take effect 30 days after notification.</p>

          <h3>10. Contact Us</h3>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> <a href="mailto:info@versytechnologies.com">info@versytechnologies.com</a></li>
            <li><strong>Telephone:</strong> +39 348 824 1998</li>
          </ul>
          </TermsContent>
      </TermsCard>
    </TermsContainer>
  );
};

export default TermsOfService;
