import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CreateProjectContainer, Header, Title, CardsContainer, ProjectCard } from '../styles/CreateProjectStyles';
import ProjectConfiguration from './creation_steps_components/ProjectConfiguration';
import CreationConfirmation from './creation_steps_components/CreationConfirmation';
import UpgradePlan from './UpgradePlan';
import CheckUpgrade from './creation_steps_components/CheckUpgrade';

const StepEnum = {
  SELECT_PROJECT_TYPE: 'select_type',
  PROJECT_CONFIGURATION: 'configure',
  CONFIRMATION_PAGE: 'confirmation',
  UPGRADE_PLAN_PAGE: 'upgrade_plan',
  CHECK_PAYMENT: 'check_payment',
  // Add other steps as needed
};

const CreateProject = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    projectType: '',
    propertyName: '',
    imageCover: null,
    propertyValue: '',
    isFurnished: false,
    propertyPlan: null,
    configurations: 1,
    streamingMinutes: 15,
    // Add other fields as needed
  });

  // Use useEffect only for initial redirection
  useEffect(() => {
    if (!step) {
      navigate(`/create_project/${StepEnum.SELECT_PROJECT_TYPE}`, { replace: true });
    }
  }, [step, navigate]);

  const handleProjectTypeSelection = (type) => {
    setProjectData({ ...projectData, projectType: type });
    navigate(`/create_project/${StepEnum.PROJECT_CONFIGURATION}`);
  };

  const handleNextStep = (nextStep) => {
    navigate(`/create_project/${nextStep}`);
  };

  const renderStepContent = () => {
    switch (step) {
      case StepEnum.SELECT_PROJECT_TYPE:
        return (
          <CardsContainer>
            <ProjectCard onClick={() => handleProjectTypeSelection('Upload House Plan')}>
              <img src={`${process.env.PUBLIC_URL}/house_plan_create_image.jpg`} alt="Upload House Plan" />
              <h2>Upload House Plan</h2>
              <p>Create a project starting from a house plan in .dwg or .pdf format. <br></br><br></br>After project submission it could take up to 48 hours for the virtual house to be ready</p>
            </ProjectCard>
            <ProjectCard 
              style={{ backgroundColor: '#f0f0f0', color: '#888', cursor: 'not-allowed' }} 
              onClick={(e) => e.preventDefault()}
            >
              <img 
                src={`${process.env.PUBLIC_URL}/template_create_image.jpg`}
                alt="Template" 
                style={{ opacity: 0.5 }}
              />
              <h2>Template</h2>
              <p>Start applying immediately our AI model on a ready-made house with furniture inside. <br></br><br></br>Enjoy creating new configurations on a set of different properties</p>
            </ProjectCard>
            <ProjectCard 
              style={{ backgroundColor: '#f0f0f0', color: '#888', cursor: 'not-allowed' }} 
              onClick={(e) => e.preventDefault()}
            >
              <img 
                src={`${process.env.PUBLIC_URL}/empty_create_image.jpg`} 
                alt="Empty" 
                style={{ opacity: 0.5 }}
              />
              <h2>Empty</h2>
              <p>Create a project from scratch with no walls at all and all your creativity on your side<br></br><br></br>You are in complete control of the structure of the property</p>
            </ProjectCard>
          </CardsContainer>
        );
      case StepEnum.PROJECT_CONFIGURATION:
        return (
          <ProjectConfiguration 
            projectData={projectData} 
            setProjectData={setProjectData} 
            nextStep={handleNextStep}  // Navigate to the next step
            StepEnum={StepEnum}
          />
        );
      case StepEnum.CONFIRMATION_PAGE:
        return <CreationConfirmation />;
      case StepEnum.UPGRADE_PLAN_PAGE:
        return <UpgradePlan />;
      case StepEnum.CHECK_PAYMENT:
        return <CheckUpgrade nextStep={handleNextStep} StepEnum={StepEnum} />;
      default:
        return null;
    }
  };

  return (
    <CreateProjectContainer>
      <Header>
        <Title>Create Project</Title>
      </Header>
      {renderStepContent()}
    </CreateProjectContainer>
  );
};

export default CreateProject;
