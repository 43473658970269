import React, { useState } from 'react';
import { HelpContainer, Header, FormContainer, FormGroup, Label, Input, TextArea, SubmitButton } from '../styles/HelpStyles';

const Help = () => {
  const [helpRequest, setHelpRequest] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    description: '',
    attachments: null,
  });

  const handleInputChange = (field, value) => {
    setHelpRequest({ ...helpRequest, [field]: value });
  };

  const handleFileChange = (e) => {
    setHelpRequest({ ...helpRequest, attachments: e.target.files[0] });
  };

  const handleSubmit = () => {
    // Logic to send the help request via email or API
    console.log('Help request submitted:', helpRequest);
  };

  return (
    <HelpContainer>
      <Header>
        <h1>Help & Support</h1>
      </Header>
      <FormContainer>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={helpRequest.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            value={helpRequest.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <Input
            type="tel"
            value={helpRequest.phone}
            onChange={(e) => handleInputChange('phone', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Subject</Label>
          <Input
            type="text"
            value={helpRequest.subject}
            onChange={(e) => handleInputChange('subject', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description of the Issue</Label>
          <TextArea
            value={helpRequest.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Attachments (optional)</Label>
          <Input type="file" accept="image/*,application/pdf" onChange={handleFileChange} />
          {helpRequest.attachments && (
            <p>{helpRequest.attachments.name}</p>
          )}
        </FormGroup>
        <SubmitButton onClick={handleSubmit}>Submit Request</SubmitButton>
      </FormContainer>
    </HelpContainer>
  );
};

export default Help;
