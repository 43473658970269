import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../../hooks/useApiClient';

const CheckUpgrade = ({ nextStep, StepEnum }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUpgrade = async () => {
      try {
        const response = await apiClient.get('/user/check_upgrade');
        console.log("response NEED UPGRADE?:", response.data.need_upgrade);
        if (response.data.need_upgrade) {
            nextStep(StepEnum.UPGRADE_PLAN_PAGE);
        } else {
            console.log("false indeed: ", response.data.need_upgrade);
            nextStep(StepEnum.CONFIRMATION_PAGE);
        }
      } catch (error) {
        console.error('Error checking for upgrade:', error);
      }
    };

    checkUpgrade();
  }, [apiClient, nextStep, StepEnum]);

  return <div>Processing...</div>;
};

export default CheckUpgrade;
