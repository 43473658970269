import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  KeywordsHeader,
  KeywordsData,
  TableData,
  ErrorText,
  LoadingText,
  ImageGallery,
  ImageThumbnail,
  FullScreenImage,
  UpdateButton,
  KeywordInput
} from '../styles/AssetsTableStyles';
import { useApiClient } from '../hooks/useApiClient';

const AssetsTable = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fullscreenImage, setFullscreenImage] = useState(null); // State for fullscreen image
  const [editedAssetIds, setEditedAssetIds] = useState(new Set()); // State for tracking edited assets
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await apiClient.get('/assets/');
        setAssets(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load assets. Please try again later.');
        console.error('Error fetching assets: ', error);
        setLoading(false);
      }
    };

    fetchAssets();
  }, []); // Ensure apiClient has a stable reference to avoid repeated calls

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(fullscreenImage === imageUrl ? null : imageUrl); // Toggle fullscreen mode
  };

  const handleKeywordsChange = (index, newKeywords) => {
    const updatedAssets = [...assets];
    updatedAssets[index].keywords = newKeywords.split(',').map(kw => kw.trim());
    setAssets(updatedAssets);
    setEditedAssetIds(prev => new Set(prev).add(updatedAssets[index].id)); // Track edited asset
  };

  const handleUpdate = async () => {
    try {
      const assetsToUpdate = assets.filter(asset => editedAssetIds.has(asset.id)); // Filter only edited assets
      if (assetsToUpdate.length === 0) {
        alert('No changes to update.');
        return;
      }

      await Promise.all(
        assetsToUpdate.map(asset => apiClient.put(`/assets/${asset.id}`, { keywords: asset.keywords }))
      );
      alert('Edited assets updated successfully!');
      setEditedAssetIds(new Set()); // Clear the edited asset IDs after update
    } catch (error) {
      alert('Failed to update assets. Please try again later.');
      console.error('Error updating assets:', error);
    }
  };

  if (loading) return <LoadingText>Loading assets...</LoadingText>;
  if (error) return <ErrorText>{error}</ErrorText>;

  return (
    <TableContainer>
      <UpdateButton onClick={handleUpdate}>Update</UpdateButton>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Images</TableHeader>
            <TableHeader>ID</TableHeader>
            <TableHeader>Name</TableHeader>
            <KeywordsHeader>Keywords</KeywordsHeader>
            <TableHeader>Dimensions</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset, index) => (
            <TableRow key={asset.id}>
              <TableData>
                <ImageGallery>
                  {asset.images.map((image) => (
                    <ImageThumbnail
                      key={image.id}
                      src={image.image_url}
                      alt={`Asset ${asset.id}`}
                      onClick={() => handleImageClick(image.image_url)}
                    />
                  ))}
                </ImageGallery>
              </TableData>
              <TableData>{asset.id}</TableData>
              <TableData>{asset.name}</TableData>
              <KeywordsData>
                <KeywordInput
                  value={asset.keywords?.join(', ')}
                  onChange={(e) => handleKeywordsChange(index, e.target.value)}
                />
              </KeywordsData>
              <TableData>{asset.dimensions}</TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {fullscreenImage && (
        <FullScreenImage src={fullscreenImage} alt="Fullscreen" onClick={() => setFullscreenImage(null)} />
      )}
    </TableContainer>
  );
};

export default AssetsTable;
