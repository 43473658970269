import styled from 'styled-components';

export const TableContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: auto;
  position: relative; /* For positioning the fullscreen image */
`;

export const Table = styled.table`
  min-width: 1200px;
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  white-space: nowrap;
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

/* Specific styles for the Keywords column */
export const KeywordsHeader = styled(TableHeader)`
  min-width: 500px; /* Set minimum width for the header */
`;

export const KeywordsData = styled(TableData)`
  min-width: 500px; /* Set minimum width for the data cells */
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;

export const LoadingText = styled.p`
  color: #007bff;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;

export const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ImageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const FullScreenImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid white;
`;

export const UpdateButton = styled.button`
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const KeywordInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;
