import styled from 'styled-components';

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

export const AuthCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const AuthTitle = styled.h1`
  color: #333333;
  font-size: 22px;
  margin-bottom: 10px;
`;

export const AuthInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  font-size: 16px;
  box-sizing: border-box;
`;

export const AuthButton = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;

export const AuthLink = styled.p`
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  a {
    color: #007bff;
    text-decoration: none;
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin: 5px 0 10px;
`;

export const PasswordCriteria = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;
`;

export const PasswordCriteriaItem = styled.li`
  color: ${({ met }) => (met ? 'green' : 'inherit')};
  &:before {
    content: ${({ met }) => (met ? '"✅ "' : '"• "')};
    margin-right: 5px;
  }
`;