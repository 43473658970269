import React, { useState, useEffect } from 'react';
import { Container, MainContent, ContentWrapper, ProjectDetailsWrapper, DashboardWrapper, Card, Title, Button } from '../styles/HomeStyles';
import { useNavigate, useParams, Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import ProjectDetails from './ProjectDetails';
import CreateProject from './CreateProject';
import Account from './Account';  
import Help from './Help';  
import EditProject from './EditProject'; 
import PixelStreamingComponent from './PixelStreamingComponent';
import AssetsTable from './AssetsTable';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { section } = useParams(); 

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSectionChange = (newSection) => {
    navigate(`/${newSection}`);
  };

  const handleEditProject = () => {
    setIsEditing(true);
    handleSectionChange('edit_project');
  };

  const handleSaveProject = (updatedProjectData) => {
    setSelectedProject(updatedProjectData);
    setIsEditing(false);
    handleSectionChange('projects');
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    handleSectionChange('projects');
  };

  const handleDeleteProject = (projectId) => {
    console.log(`Project with ID ${projectId} deleted.`);
    setSelectedProject(null);
    setIsEditing(false);
    handleSectionChange('projects');
  };

  const renderSection = () => {
    switch (section) { 
      case 'projects':
        return <Dashboard 
                 setSelectedProject={setSelectedProject} 
                 activeSection={section} 
                 setActiveSection={handleSectionChange} 
                 selectedProject={selectedProject} 
               />;
      case 'create_project':
        return (
          <Routes>
            <Route path=":step" element={<CreateProject />} />
            <Route path="" element={<CreateProject />} /> 
          </Routes>
        );
      case 'account':
        return <Account />;
      case 'help':
        return <Help />;
      case 'assets':
        return <AssetsTable />;
      case 'streaming':
        return <PixelStreamingComponent />;
      case 'edit_project':
        return (
          <EditProject 
            project={selectedProject} 
            onSave={handleSaveProject} 
            onCancel={handleCancelEdit} 
          />
        );
      default:
        return <Dashboard 
                 setSelectedProject={setSelectedProject} 
                 activeSection={section} 
                 setActiveSection={handleSectionChange} 
                 selectedProject={selectedProject} 
               />;
    }
  };

  if (!isLoggedIn) {
    return (
      <Container>
        <Card>
          <Title>Welcome to Versy Tech</Title>
          <Button onClick={() => navigate('/login')}>Login</Button>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Sidebar 
        setActiveSection={handleSectionChange} 
        setIsLoggedIn={setIsLoggedIn} 
        activeSection={section} 
      />
      <MainContent>
        <ContentWrapper>
          <DashboardWrapper>
            {renderSection()} 
          </DashboardWrapper>
          {section === 'projects' && selectedProject && !isEditing && (
            <ProjectDetailsWrapper>
              <ProjectDetails 
                project={selectedProject} 
                onEdit={handleEditProject} 
                onDelete={handleDeleteProject}  
              />
            </ProjectDetailsWrapper>
          )}
        </ContentWrapper>
      </MainContent>
    </Container>
  );
};

export default Home;
