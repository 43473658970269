import React from 'react';
import { SidebarContainer, Logo, NavItem, NavItemContent, BottomNavContainer } from '../styles/SidebarStyles';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ setActiveSection, setIsLoggedIn, activeSection }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    navigate('/');  // Redirect to the home page after logout
  };

  return (
    <SidebarContainer>
      <Logo>
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
      </Logo>
      <NavItem 
        onClick={() => setActiveSection('projects')} 
        $active={activeSection === 'projects'}  // Use the activeSection prop
      >
        <NavItemContent>
          <img src={`${process.env.PUBLIC_URL}/house_icon.png`} alt="icon" />
          Projects
        </NavItemContent>
      </NavItem>
      <NavItem 
        onClick={() => setActiveSection('assets')} 
        $active={activeSection === 'assets'}  // Use the activeSection prop
      >
        <NavItemContent >
          <img src={`${process.env.PUBLIC_URL}/objects_icon.png`} alt="icon" />
          Assets
        </NavItemContent>
      </NavItem>
      <NavItem 
        onClick={() => setActiveSection('analytics')} 
        $inactive={true}
        $active={activeSection === 'analytics'}  // Use the activeSection prop
      >
        <NavItemContent $inactive={true}>
          <img src={`${process.env.PUBLIC_URL}/analytics_icon.png`} alt="icon" />
          Analytics
        </NavItemContent>
      </NavItem>
      <NavItem 
        onClick={() => setActiveSection('integrations')} 
        $inactive={true}
        $active={activeSection === 'integrations'   }     
      >
        <NavItemContent $inactive={true}>
          <img src={`${process.env.PUBLIC_URL}/integrations_icon.png`} alt="icon" />
          Integrations
        </NavItemContent>
      </NavItem>

      <BottomNavContainer>
        <NavItem 
          onClick={() => setActiveSection('help')}
          $active={activeSection === 'help'}  // Use the activeSection prop
        >
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/customer_service_icon.png`} alt="icon" />
            Help
          </NavItemContent>
        </NavItem>
        <NavItem 
          onClick={() => setActiveSection('account')}
          $active={activeSection === 'account'}  // Use the activeSection prop
        >
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/user_pic_icon.png`} alt="icon" />
            Account
          </NavItemContent>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavItemContent>
            <img src={`${process.env.PUBLIC_URL}/logout_icon.png`} alt="icon" />
            Logout
          </NavItemContent>
        </NavItem>
      </BottomNavContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
