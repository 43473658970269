import React from 'react';
import { PrivacyPolicyContainer, PrivacyPolicyCard, PrivacyPolicyTitle, PrivacyPolicyContent } from '../styles/PrivacyPolicyStyles';

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyCard>
        <PrivacyPolicyTitle>Privacy Policy</PrivacyPolicyTitle>
        <PrivacyPolicyContent>
      <p><strong>Effective Date:</strong> August 16, 2024</p>

          <p><strong>Versy Srl</strong> ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://versy.tech">versy.tech</a>, use our services, or engage with our Text-to-Space platform.</p>

          <h3>1. Information We Collect</h3>
          <p><strong>Personal Information</strong><br />
          We collect the following personal information when you create an account on Versy:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
          </ul>

          <p><strong>Data Collection Method</strong><br />
          We only collect personal information directly from you when you provide it through account creation or customer support requests. We do not collect any data automatically.</p>

          <h3>2. How We Use Your Information</h3>
          <p>We use your personal information for the following purposes:</p>
          <ul>
            <li><strong>Account Management:</strong> To manage your account and provide you with access to our Text-to-Space platform.</li>
            <li><strong>Customer Support:</strong> To respond to your inquiries and provide customer support.</li>
          </ul>

          <h3>3. Data Sharing and Disclosure</h3>
          <p><strong>Third-Party Sharing</strong><br />
          We do not share your personal data with third parties, except in the case of payment processing, which is handled by Stripe. Stripe retains and processes all payment-related information. You can review Stripe’s privacy policy <a href="https://stripe.com/privacy">here</a>.</p>

          <p><strong>Legal Requirements</strong><br />
          We will only disclose your personal information if required by law, such as in response to a court order.</p>

          <h3>4. Data Retention</h3>
          <p>We retain your personal information for as long as your account is active or as needed to provide you services. If your account is inactive for five years, your data will be automatically deleted.</p>

          <h3>5. Data Security</h3>
          <p>We implement industry-standard encryption and conduct regular security audits to protect your personal information. In the event of a data breach, we will notify affected users within 72 hours via email and take necessary measures to mitigate any damage.</p>

          <h3>6. Cookies and Tracking</h3>
          <p>We do not use cookies or any other tracking technologies on our platform.</p>

          <h3>7. International Data Transfers</h3>
          <p>All data is hosted on servers located in France, Europe, ensuring compliance with the General Data Protection Regulation (GDPR).</p>

          <h3>8. Children's Privacy</h3>
          <p>We do not knowingly collect or solicit personal information from children under the age of 16. If we learn that we have collected personal data from a child under 16, we will delete that information as quickly as possible.</p>

          <h3>9. Updates to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you via email. The last update was made on August 16, 2024.</p>

          <h3>10. Contact Us</h3>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> <a href="mailto:info@versytechnologies.com">info@versytechnologies.com</a></li>
            <li><strong>Telephone:</strong> +39 348 824 1998</li>
          </ul>
          </PrivacyPolicyContent>
          </PrivacyPolicyCard>
        </PrivacyPolicyContainer>
        );
};

export default PrivacyPolicy;
